// Header.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import HighlightedName from './HighlightedName';
import './Header.css';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="header">
      <div className="header__container">
        {/* Logo or Name */}
        <Link to="/" className="header__logo-link">
          <HighlightedName />
        </Link>

        {/* Hamburger Icon */}
        <button className="header__hamburger" onClick={toggleMenu}>
          ☰
        </button>

        {/* Navigation Links */}
        <nav className={`header__nav ${isMenuOpen ? 'header__nav--open' : ''}`}>
          <ul className="header__nav-list">
            <li className="header__nav-item">
              <Link to="/" className="header__nav-link" onClick={toggleMenu}>Home</Link>
            </li>
            <li className="header__nav-item">
              <Link to="/about" className="header__nav-link" onClick={toggleMenu}>About</Link>
            </li>
            <li className="header__nav-item">
              <Link to="/projects" className="header__nav-link" onClick={toggleMenu}>Projects</Link>
            </li>
            <li className="header__nav-item">
              <Link to="/contact" className="header__nav-link" onClick={toggleMenu}>Contact</Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
