import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css';
import Timeline from '../components/Timeline';
import DailyActivityBar from '../components/DailyActivityBar';

const Home = () => {
  const projects = [
    {
      title: "Generative AI-Powered Solution",
      description:
        "A rapid-prototyping platform integrating Generative AI for real-time content creation, built with React and Node.js.",
      viewLink: "/projects/ai-solution",
      demoLink: "https://demo.ai-solution.com"
    },
    {
      title: "Dynamic SaaS Application",
      description:
        "An enterprise-grade SaaS product built with Python and Django, designed for scalable, full-stack deployment.",
      viewLink: "/projects/saas-application",
      demoLink: "https://demo.saas-application.com"
    },
    {
      title: "E-commerce Recommendation Engine",
      description:
        "A recommendation system powered by machine learning, built to enhance customer engagement for e-commerce.",
      viewLink: "/projects/recommendation-engine",
      demoLink: "https://demo.recommendation-engine.com"
    },
    {
      title: "Real-Time Collaboration Tool",
      description:
        "A collaborative platform developed with WebSockets, enabling real-time updates and team collaboration.",
      viewLink: "/projects/collaboration-tool",
      demoLink: "https://demo.collaboration-tool.com"
    },
    {
      title: "Interactive Data Visualization Platform",
      description:
        "A platform utilizing D3.js and React for creating interactive and insightful data visualizations.",
      viewLink: "/projects/data-visualization",
      demoLink: "https://demo.data-visualization.com"
    }
  ];

  return (
    <main className="home">
      {/* Hero Section */}
      <section className="home__hero">
        <h1 className="home__title">Hi, I'm Aditya Dharoor Kamsali (DK)</h1>
        <p className="home__subtitle">
          Senior Software Engineer with expertise in full-stack development, rapid prototyping and Generative AI solutions.
        </p>
      </section>

      {/* Timeline and Daily Activity Section */}
      <section className="home__timeline-activity">
        <div className="home__graph-title">Professional Timeline</div>
        <Timeline />
        <div className="home__graph-title">Typical Day</div>
        <DailyActivityBar />
      </section>

      {/* About Section */}
      <section className="home__about" id="about">
        <h2 className="home__section-title">About Me</h2>
        <p className="home__about-text">
          With over a decade of experience in the tech industry, I specialize in building fast, responsive applications
          with a keen focus on both frontend and backend technologies. I’m passionate about leveraging Generative AI
          to enhance user experiences and accelerate development cycles, enabling swift delivery of functional prototypes.
          Let’s bring ideas to life together.
        </p>
        <Link to="/about" className="home__link">Learn more about me</Link>
      </section>

      {/* Featured Projects Section */}
      <section className="home__projects" id="projects">
        <h2 className="home__section-title">Featured Projects</h2>
        <div className="home__project-grid">
          {projects.map((project, index) => (
            <div key={index} className="home__project-card">
              <h3 className="home__project-title">{project.title}</h3>
              <p className="home__project-description">{project.description}</p>
              <div className="home__project-buttons">
                <Link to={project.viewLink} className="home__project-link">View Project</Link>
                <a href={project.demoLink} target="_blank" rel="noopener noreferrer" className="home__project-link">
                  Live Demo
                </a>
              </div>
            </div>
          ))}
        </div>
        <Link to="/projects" className="home__link">See all projects</Link>
      </section>

      {/* Contact Section */}
      <section className="home__contact">
        <h2 className="home__section-title">Let's Connect</h2>
        <p className="home__contact-text">
          Whether you want to collaborate on a project, explore Generative AI, or discuss rapid prototyping,
          feel free to reach out.
        </p>
        <Link to="/contact" className="home__cta home__cta--contact">Get in Touch</Link>
      </section>
    </main>
  );
};

export default Home;
